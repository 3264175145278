












import { Component, Vue } from 'vue-property-decorator'
import { activeCallStore } from '@/store'

@Component({
  components: {
    DraggableResizable: () => import('@/components/UI/DraggableResizable.vue'),
    MeetingDialog: () => import('../MeetingDialog/index.vue'),
  },
})
export default class TheMeetingMd extends Vue {
  private callWindowWidth = 350

  // default window size
  w = 420
  h = 730

  get startX () {
    return this.activeCall ? this.callWindowWidth : 0
  }

  get activeCall () {
    return activeCallStore.state.activeCall
  }

  mounted () {
    if (document.documentElement.clientHeight < this.h) {
      this.h = document.documentElement.clientHeight
    }
  }
}
